<template>
     <a-drawer
      title="发货"
      placement="right"
      :closable="false"
      :visible="visible"
      @close="$emit('close')"
      :width="800"
      :bodyStyle="{padding:0}"
    >
        <div class="studentInfo">
            <span>学员姓名：张三</span>
            <span>联系电话：******</span>
            <span>微信：*******</span>
            <span>地址：********</span>
        </div>
        <div class="formBody">
            <a-form
                v-bind="formItemLayout"
            >
                <a-form-item label="课程包名称">
                    <a-select placeholder="请选择课程包">
                    <a-select-option :value="1" >
                       1
                    </a-select-option>
                    </a-select>
                </a-form-item>
                 <a-form-item label="选择科目">
                    <a-select placeholder="请选择科目">
                        <a-select-option :value="1" >
                        1
                        </a-select-option>
                    </a-select>
                     <a-checkbox-group :options="plainOptions" />
                </a-form-item>
                <a-form-item label="价格">
                    <a-input  placeholder="请输入价格" />
                </a-form-item>
                <a-form-item label="快递单号">
                    <a-input  placeholder="请输入快递单号" />
                </a-form-item>
                <a-form-item label="是否微信、公众号推送">
                     <a-radio-group >
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="2">否</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
            <div style="text-align:right;padding-top:30px">
                <a-button class="mr20px" style="width:80px" @click="$emit('close')">取消</a-button>
                <a-button type="primary"  style="width:80px">确定</a-button>
            </div>
        </div>
    </a-drawer>
</template>
<script>
export default {
    name: 'addClasses',
    props: ['visible'],
    data(){
        this.plainOptions = 
            [{
                label:'全部',
                value:'1'
            },
            {
                label:'题库',
                value:'2'
            },
            {
                label:'课程',
                value:'3'
            }
        ];
        return{
            formItemLayout: {
                labelCol: { span: 6 },
                wrapperCol: { span:10 },
            },
        }
    }
}
</script>
<style  scoped>
.studentInfo{
    display: flex;
    justify-content: space-between;
    color: #888888;
    border-bottom: 1px solid #eee;
    padding: 20px;
}
.formBody{
    padding: 20px;
}
</style>
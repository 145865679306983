<template>
    <a-table
        :columns="columns"
        :data-source="data"
    >
        <template slot="operation">
            <i class="z-icon edit" @click="()=>{currentItem = item ; visible = true}"></i>
            <i class="z-icon delete" @click="deleteItem(item.id)"></i>
        </template>
    </a-table>
</template>
<script>
const columns = [
  { title: '课程包名称', dataIndex: 'name', align:'center' }, 
  { title: '内含科目', dataIndex: 'age', align:'center' }, 
  { title: '学习进度', dataIndex: 'wechart', align:'center' }, 
  { title: '到期时间', dataIndex: 'status', align:'center' }, 
  { title: '合同', dataIndex: 'content1', align:'center' }, 
  { title: '金额', dataIndex: 'content2', align:'center' }, 
  { title: '优惠券', dataIndex: 'content3', align:'center' },
  { title: '应收', dataIndex: 'content4', align:'center' },
  { title: '实收', dataIndex: 'content5', align:'center' },
  { title: '尾款', dataIndex: 'content6', align:'center' },
  { title: '订单状态', dataIndex: 'content7', align:'center' },
  { title: '购买来源', dataIndex: 'content8', align:'center' },
  { title: '交易时间', dataIndex: 'content9', align:'center' },
  { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align:'center' },
];
export default {
    name: 'classBagRecord',
    data(){
        return{
            columns,
            data: [],
            currentItem: null,
            visible: false
        }
    },
    methods:{
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                },
            });
        }
    }
}
</script>
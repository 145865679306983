<template>
    <div class="detail pd20x flex1">
        <studentClassBag :visible = "openClassBag" @close="openClassBag = false"></studentClassBag>
        <div class="detailInfo">
            <div class="imgWrap">
                <img src="" alt="">
            </div>
            <div>
                <div class="name">张三</div>
                <p><span class="mr10px">电话：</span><span class="mr10px">微信：</span><span >邮箱：</span></p>
                <p>地址：</p>
            </div>
            <div class="rBtn"><a-button type="primary" @click="openClassBag = true">开通课程</a-button></div>
        </div>
        <div style="margin-top:5px;padding-top:10px" class="contentBox">
            <div class="radio-line">
                <div class="radio-line-item" v-for="(item, index) in tabList" :key="index" :class="{active: index == activeIndex}" @click="activeIndex = index">{{item}}</div>
            </div>
            <template v-if="activeIndex == 0">
                <classBagRecord></classBagRecord>
            </template>
            <template v-if="activeIndex == 1">
                <orderRecord></orderRecord>
            </template>
            <template v-if="activeIndex == 2">
                <dealRecord></dealRecord>
            </template>
        </div>
    </div>
</template>
<script>
import classBagRecord from './classBagRecord'
import orderRecord from './orderRecord'
import dealRecord from './dealRecord'
import studentClassBag from './studentClassBag'
export default {
    name: 'studentsDetail',
    components: { classBagRecord, orderRecord, dealRecord, studentClassBag },
    data(){
        this.tabList = ['课程包信息','发货记录', '处理记录']
        return{
            activeIndex: 0,
            openClassBag: false
        }
    }
}
</script>
<style scoped>
.detail{
    display: flex;
    flex-direction: column;
}
.contentBox{
    flex: 1;
}
</style>
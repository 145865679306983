<template>
    <a-table
        :columns="columns"
        :data-source="data"
    >
        <template slot="operation">
            <i class="z-icon delete" @click="deleteItem(item.id)"></i>
        </template>
    </a-table>
</template>
<script>
const columns = [
  { title: '课程包名称', dataIndex: 'name', align:'center' }, 
  { title: '发送类型', dataIndex: 'age', align:'center' }, 
  { title: '状态', dataIndex: 'wechart', align:'center' }, 
  { title: '快递公司', dataIndex: 'status', align:'center' }, 
  { title: '快递单号', dataIndex: 'content1', align:'center' }, 
  { title: '时间', dataIndex: 'content2', align:'center' }, 
  { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align:'center' },
];
export default {
    name: 'classBagRecord',
    data(){
        return{
            columns,
            data: [],
            currentItem: null,
            visible: false
        }
    },
    methods:{
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                },
            });
        }
    }
}
</script>